<template lang="pug">


div
  v-dialog(v-model='dialog' :close-on-content-click='false' auto scrollable max-width="390"  content-class="my-custom-dialog")
    template(v-slot:activator='{ on, attrs }')
      .combobox
        .click-item(v-bind='attrs' v-on='on')
        v-btn.clear-icon(icon v-if="!required && value" @click='clearSelection')
          v-icon mdi-close
        v-combobox( ref="selectComboboxRef" :label="label" :placeholder="label" :value="value"  :rules="[v => !required || !!v  || 'Pflichtfeld']"  return-object  item-text="name" hide-details append-icon="mdi-pencil" readonly dense outlined )
    v-card( :style="{ height: visualViewport < 480 ? visualViewport + 'px' :  '390px'  }" min-height="300")
      //- Filter Items by Search Term
      .pt-4.px-4
        v-text-field(v-model="search" ref="searchField" label="Suchen..." autofocus :placeholder="label" @focus="handleIOSKeyboardAppear" clearable hide-details outlined  dense type='text')
      v-list.white.lighten-4.overflow-y-auto.py-2.toggle-list(v-if="searchResults.length > 0")

        .white(v-for="item in searchResults" :key="item.id" :id="item.id" )
          v-checkbox.mt-0.checkbox-listitem( :label="item.name" :input-value="updateItems && item.id === updateItems.id"  :multiple="false" hide-details @click="() => toggleItem(item)")
      .d-flex.justify-center(v-else)
        span.center-text.py-12 Suche nach einem {{ label }}...
      v-spacer   
      v-divider
      v-card-actions.white
        v-spacer
        v-btn(color='grey' text @click='dismiss')
          | Abbrechen
</template>

<script>
import Vue from 'vue';
import { debounce } from 'debounce';
import { cloneDeep } from 'lodash';

export default Vue.extend({
  inheritAttrs: true,
  props: ['value', 'label', 'required'],
  data() {
    const initialEntries = [];
    if (this.value) {
      initialEntries.push(this.value);
    }
    return {
      descriptionLimit: 60,
      searchResults: initialEntries,
      isLoading: false,
      updateItems: cloneDeep(this.value) ?? [],
      search: '',
      service: null,
      dialog: false,
      visualViewport: 340,
    };
  },
  metaInfo() {
    // check if maps script already added
    if (window.google?.maps) {
      this.MapsInit();
      return {};
    }
    return {
      script: [
        {
          src: `${process.env.VUE_APP_GOOGLE_PLACES_BASE_URL}?key=${process.env.VUE_APP_GOOGLE_PLACES_API_KEY}&libraries=places&language=de`,
          async: true,
          defer: true,
          callback: () => this.MapsInit(), // will declare it in methods
        },
      ],
    };
  },
  watch: {
    search(value) {
      if (!this.isLoading && value && value.length > 0) {
        this.getPredictions(value);
      }
    },
    // dialog(open) {
    //   if (open) {
    //     this.$refs.searchField?.focus();
    //   }
    // },
  },
  created() {
    // delay execution of request to reduce number of requests made
    this.getPredictions = debounce(this.getPredictions, 150);
  },
  methods: {
    async clearSelection() {
      // await this.$refs.selectComboboxRef.preventDefault();
      this.updateItems = null;
      this.$emit('input', this.updateItems);
      await this.$refs.selectComboboxRef.reset();
      this.dialog = false;
    },

    toggleItem(item) {
      if (this.updateItems && this.updateItems.id === item.id) {
        this.updateItems = null;
      } else {
        this.updateItems = item;
      }
      this.itemClicked();
    },
    handleIOSKeyboardAppear() {
      setTimeout(() => {
        this.windowHeight = window.innerHeight;
        const viewportHeight = window.visualViewport?.height;

        if (viewportHeight) {
          this.visualViewport = viewportHeight - 50;
        }
      }, 50);
    },
    itemClicked() {
      this.$emit('input', this.updateItems);
      this.closeDialog();
    },
    dismiss() {
      this.updateItems = cloneDeep(this.value);
      this.closeDialog();
    },
    async closeDialog() {
      // await this.$refs.selectComboboxRef.focus();
      this.search = '';
      this.dialog = false;
      const initialEntries = [];

      if (this.value) {
        initialEntries.push(this.value);
      }
      this.searchResults = initialEntries;
    },

    MapsInit() {
      this.service = new window.google.maps.places.AutocompleteService();
      /**
       * With the current implementation (debounce and min. chars) pay-per-request should be most cost efficient
       * Might need to use a session token in future:
       * https://developers.google.com/maps/documentation/javascript/places-autocomplete#session_tokens
       */
    },
    displaySuggestions(predictions, status) {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        this.searchResults = [];
        return;
      }

      this.searchResults = predictions.map((prediction) => ({
        name: prediction.description,
        id: prediction.place_id,
      }));
    },
    async getPredictions(value) {
      if (!this.service) return;
      this.isLoading = true;
      await this.service.getPlacePredictions(
        {
          input: value,
          types: ['(cities)'],
        },
        this.displaySuggestions
      );
      this.isLoading = false;
    },
  },

  beforeMount() {
    window.visualViewport.addEventListener(
      'resize',
      this.handleIOSKeyboardAppear
    );
  },
  beforeDestroy() {
    window.visualViewport.removeEventListener(
      'resize',
      this.handleIOSKeyboardAppear
    );
  },
});
</script>

<style lang="scss">
@import '@/assets/style/main';

.v-autocomplete.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: none;
}
.my-custom-dialog {
  @media only screen and (max-width: 640px) {
    align-self: flex-start;
  }
}
</style>
