import { EducationType, Education } from '@/types/education';

interface GraduationSuggesion {
  name: string;
  countries?: string[]; // alpha2
  educationTypes?: EducationType[];
  educationSubTypes?: string[]; // e.g. Gymnasium
}

export const graduationSuggestions: GraduationSuggesion[] = [
  {
    name: 'Hauptschulabschluss',
    countries: ['de'],
    educationTypes: [EducationType.SCHOOL],
  },
  {
    name: 'Qualif. Hauptschulabschluss',
    countries: ['de'],
    educationTypes: [EducationType.SCHOOL],
  },
  {
    name: 'Mittlerer Schulabschluss',
    countries: ['de'],
    educationTypes: [EducationType.SCHOOL],
  },
  {
    name: 'Fachabitur',
    countries: ['de'],
    educationTypes: [EducationType.SCHOOL],
  },
  {
    name: 'Abitur',
    countries: ['de'],
    educationTypes: [EducationType.SCHOOL],
    // educationSubTypes: ['Gymnasium'],
  },
  {
    name: 'Helfer/in',
    countries: ['de'],
    educationTypes: [EducationType.EDUCATION],
  },
  {
    name: 'Berufsausbildung (Geselle usw.)',
    countries: ['de'],
    educationTypes: [EducationType.EDUCATION],
  },
  {
    name: 'Aufstiegsfortbildung (Meister usw.)',
    countries: ['de'],
    educationTypes: [EducationType.EDUCATION],
  },
  {
    name: 'Weiterbildung',
    countries: ['de'],
    educationTypes: [EducationType.EDUCATION],
  },
  {
    name: 'Bachelor',
    countries: ['de'],
    educationTypes: [EducationType.EDUCATION],
  },
  {
    name: 'Master',
    countries: ['de'],
    educationTypes: [EducationType.EDUCATION],
  },
  {
    name: 'Diplom',
    countries: ['de'],
    educationTypes: [EducationType.EDUCATION],
  },
  {
    name: 'Magister',
    countries: ['de'],
    educationTypes: [EducationType.EDUCATION],
  },
  {
    name: '1. Staatsprüfung',
    countries: ['de'],
    educationTypes: [EducationType.EDUCATION],
  },
  {
    name: '2. Staatsprüfung',
    countries: ['de'],
    educationTypes: [EducationType.EDUCATION],
  },
  {
    name: '3. Staatsprüfung',
    countries: ['de'],
    educationTypes: [EducationType.EDUCATION],
  },
  {
    name: 'Promotion',
    countries: ['de'],
    educationTypes: [EducationType.EDUCATION],
  },
  {
    name: 'Professur',
    countries: ['de'],
    educationTypes: [EducationType.EDUCATION],
  },
  {
    name: 'Facharzt/-ärztin',
    countries: ['de'],
    educationTypes: [EducationType.EDUCATION],
  },
  {
    name: 'Fachanwalt/-anwältin',
    countries: ['de'],
    educationTypes: [EducationType.EDUCATION],
  },
  {
    name: 'Steuerberater/in',
    countries: ['de'],
    educationTypes: [EducationType.EDUCATION],
  },
  {
    name: 'Wirtschaftsprüfer/in',
    countries: ['de'],
    educationTypes: [EducationType.EDUCATION],
  },
  {
    name: 'Sonstiger Abschluss',
  },
];

const visibleForCountry = (
  suggestion: GraduationSuggesion,
  educationItem: Education
) => {
  if (!suggestion.countries) return true;
  return (
    suggestion.countries.length > 0 &&
    educationItem.country &&
    suggestion.countries.includes(educationItem.country?.alpha2)
  );
};

const visibleForEducationType = (
  suggestion: GraduationSuggesion,
  educationItem: Education
) => {
  if (!suggestion.educationTypes) return true;
  return (
    suggestion.educationTypes.length > 0 &&
    suggestion.educationTypes.includes(educationItem.type)
  );
};

const visibleForEducationSubType = (
  suggestion: GraduationSuggesion,
  educationItem: Education
) => {
  if (!suggestion.educationSubTypes) return true;
  return (
    suggestion.educationSubTypes.length > 0 &&
    suggestion.educationSubTypes.some((r) => educationItem.level?.includes(r))
  );
};

export const filterSuggestions = (
  educationItem: Education,
  allCountries = false
) => {
  return graduationSuggestions
    .filter(
      (item) =>
        (allCountries || visibleForCountry(item, educationItem)) &&
        visibleForEducationType(item, educationItem) &&
        visibleForEducationSubType(item, educationItem)
    )
    .map((item) => item.name);
};
