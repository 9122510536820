<template lang="pug">
div
  v-dialog(v-model='dialog' :close-on-content-click='false' auto scrollable max-width="390"  content-class="my-custom-dialog")
    template(v-slot:activator='{ on, attrs }')
      //- v-combobox(v-if="returnObject" ref="selectComboboxRef" :label="label" :placeholder="placeholder" :value="value" :rules="rules" :required="required"  hide-details   small-chips append-icon="mdi-pencil" @click:append="dialog = true" @focus="dialog = true"   :multiple="multiple" deletable-chips readonly='' dense outlined v-bind='attrs' v-on='on')
      .combobox.select-dialog-combobox
        .click-item(v-bind='attrs' v-on='on' )
        v-btn.clear-icon(icon v-if="!required && (multiple ? value && value.length > 0 : value)" @click='clearSelection')
          v-icon mdi-close
        v-combobox.disable( ref="selectComboboxRef" :label="label"  :placeholder="placeholder" :value="formatValue(value)" @click.prevent.stop="dialog = true" :return-object='!!returnObject' :item-text="itemText" :item-value="itemValue" :rules="rules" :required="!!required" hide-details :small-chips="multiple" append-icon="mdi-pencil" :multiple="multiple" deletable-chips readonly dense outlined )
    v-card(:style="{ height: visualViewport < 480 ? visualViewport + 'px' : searchItems ? '390px' : 'auto' }" min-height="300")
      //- Filter Items by Search Term
      .pa-4(v-if="searchItems")
        v-text-field(v-model="searchTerm" ref="searchField" label="Suchen..."  :placeholder="placeholder" @focus="handleIOSKeyboardAppear" autofocus clearable hide-details outlined  dense type='text')
      v-list.white.lighten-4.overflow-y-auto.py-2.toggle-list(v-if="returnObject")
        .white(v-for="item in filteredItems" :key="item[itemValue]" :id="item[itemValue]" )
            v-checkbox.mt-0.checkbox-listitem( v-model="updateItems" :label="item[itemText]" :value="item" :multiple="multiple" hide-details @click="itemClicked")
            
      v-list.white.lighten-4.overflow-y-auto.py-2.toggle-list(v-else)
        .white(v-for="item in filteredItems" :key="item" :id="item" )
            v-checkbox.mt-0.pt-0.checkbox-listitem( v-model="updateItems" :label="formatValue(item)" :value="item" :multiple="multiple" hide-details @click="itemClicked")
        .white.px-6.py-2(v-if="!!customText")
            v-text-field(label='Freie Eingabe' dense clearable :value="filteredItems.includes(updateItems) ? null : updateItems"  @input="(item)=> updateItems = item" :disabled="filteredItems.includes(updateItems)"  required outlined hide-details)
      v-spacer   
      v-divider
      v-card-actions.white
        v-spacer
        v-btn(color='grey' text @click='dismiss')
          | Abbrechen
        v-btn(color='primary' text='' @click='submit' v-if="multiple || customText")
          | Fertig

</template>

<script lang="js">
import Vue from 'vue';
import { cloneDeep } from 'lodash';
import { formatVacationDays, formatDurationYears } from '@/utilities/DateAndTime';
import { formatCurrency } from '@/utilities/Formats';

export default Vue.extend({
  props: [
    'items',
    'value',
    'label',
    'placeholder',
    'required',
    'rules',
    'multiple',
    'returnObject',
    'itemText',
    'itemValue',
    'searchItems',
    'formating',
    'customText'
  ],
  data() {
    return {
      dialog: false,
      searchTerm: '',
      updateItems: cloneDeep(this.value) ?? [],
      visualViewport: 340,
    };
  },

  methods: {

    handleIOSKeyboardAppear() {
      setTimeout(() => {
          this.windowHeight = window.innerHeight;
          const viewportHeight = window.visualViewport?.height;

          if(viewportHeight){
            this.visualViewport = viewportHeight - 50 ;
          }
        }, 50)
    },
    itemClicked() {
      if(!this.multiple && !this.customText){
        this.submit()
      }
    },
    formatValue(val) {
      if(this.returnObject) {
        return val;
      }
      if((val === 0 || val ===  null || val === undefined)) {
        return null;
      }
      switch (this.formating) {
        case 'currency':
            return formatCurrency(val);
        case 'vacation':
            return formatVacationDays(val);
        case 'years':
            return formatDurationYears(val);

        default:
          return val;
      }
    },
    async clearSelection() {
      // await this.$refs.selectComboboxRef.preventDefault();
      this.updateItems = this.label === "Benefits" ? [] : null;
      this.$emit('input', this.updateItems);
      await this.$refs.selectComboboxRef.reset();
      this.dialog = false;
    },
    async submit() {
      this.$emit('input', this.updateItems);
      // await this.$refs.selectComboboxRef.focus();
      this.searchTerm = "";
      this.dialog = false;
    },
    async dismiss() {
      this.updateItems = cloneDeep(this.value);
      // await this.$refs.selectComboboxRef.focus();
      this.searchTerm = "";
      this.dialog = false;
    },

  },
  computed: {
    filteredItems() {
      if (this.searchTerm != '' && this.searchTerm) {
        let filtered = this.items;
        if(this.returnObject){
          filtered = this.items.filter(item => item[this.itemText].toLowerCase().includes(this.searchTerm.toLowerCase()));

        }else{
           filtered = this.items.filter(item => item.toLowerCase().includes(this.searchTerm.toLowerCase()));
        }

      return filtered;
      }else{
        return this.items;
      }
    },
  },

  beforeMount() {

if(this.searchItems) {
    window.visualViewport.addEventListener('resize', this.handleIOSKeyboardAppear);
  }
  },
  beforeDestroy() {

if(this.searchItems) {
  window.visualViewport.removeEventListener('resize', this.handleIOSKeyboardAppear);
  }


  },
});
</script>
<style lang="scss">
@import '@/assets/style/main';

.toggle-list {
  .checkbox-listitem {
    .v-input__slot {
      padding: 14px 24px;
    }
    &:hover {
      background: $message-background-gray;
    }
    .v-label {
      color: rgba(0, 0, 0, 0.87) !important;
      padding-left: 12px;
    }
  }
}

.combobox {
  width: 100%;
  height: auto;
  position: relative;
  .click-item {
    width: 100%;
    height: auto;
    cursor: pointer;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
  }
  .v-input {
    pointer-events: none;
  }
  .clear-icon {
    position: absolute;
    right: 32px;
    top: 2px;
  }
}

.disable {
  pointer-events: none !important;
}

.my-custom-dialog {
  @media only screen and (max-width: 640px) {
    align-self: flex-start;
  }
}
</style>
