<template lang="pug">

v-row.align-center
  v-col
    slot(name="inputSlot")  

      
</template>

<script>
export default {
  components: {},
  props: ['title', 'icon'],
  computed: {},
  methods: {},
};
</script>

<style lang="sass">
@import '@/assets/style/main'
</style>
