<template lang="pug">
v-dialog(v-model='dialog' :close-on-content-click='false' auto scrollable max-width="390")
  template(v-slot:activator='{ on, attrs }')
    .combobox
      .click-item(v-bind='attrs' v-on='on')
      v-combobox(ref="areaCombobox" label="Bereich" v-model="selectedAreas" :rules="[v => (!required || (v && v.length > 0)) || 'Pflichtfeld']" :required="required" hide-details return-object item-text="name" append-icon="mdi-pencil" @click:append="dialog = true" @focus="dialog = true" small-chips  multiple deletable-chips readonly='' dense outlined v-bind='attrs' v-on='on')
  v-card
    //- v-card-text.dialog-card-text.px-0.pb-0(v-scroll.self="onScroll")
    //-   v-expansion-panels(accordion flat min-height="400" )
    //-     v-expansion-panel(v-for="areaCategory in areaCategories" v-bind:key="areaCategory.id" :id="areaCategory.id" @click="scroll(areaCategory.id)")
    //-         v-expansion-panel-header.grey.lighten-4(:class="{'font-weight-bold' : isSelectedElement(areaCategory)}" )
    //-           | {{areaCategory.name}}
    //-           span.ml-1(v-if="countSelectedElements(areaCategory) > 0")  ({{countSelectedElements(areaCategory)}})
    //-         v-expansion-panel-content
    //-                     v-list-item(v-for="area in areaCategory.items" v-bind:key="area.id" :id="area.id" @click="toggleSelected(area)")
    //-                       v-list-item-action
    //-                           v-checkbox(:input-value='isSelected(area)')
    //-                       v-list-item-content
    //-                           v-list-item-title.strong {{area.name}}
    v-list.grey.lighten-4.overflow-y-auto.py-0.area-select(min-height="400")
      v-list-group(v-for='areaCategory in areaCategories' :id="areaCategory.id" :key='areaCategory.id' no-action=''  )
        template(v-slot:activator='')
          v-list-item-content.list-title.grey.lighten-4(@click="scroll(areaCategory.id)" :class="{'font-weight-bold' : isSelectedElement(areaCategory)}")
          
            span {{areaCategory.name}}
            span.ml-1(v-if="countSelectedElements(areaCategory) > 0")  ({{countSelectedElements(areaCategory)}})
        v-list-item.white.pl-10(v-for="area in areaCategory.items" :key="area.id" :id="area.id" @click="toggleSelected(area)")
                          v-list-item-action
                              v-checkbox(:input-value='isSelected(area)')
                          v-list-item-content
                              v-list-item-title.strong {{area.name}}
    v-spacer   
    v-divider
    v-card-actions.white
      v-spacer
      v-btn(color='grey' text @click='dismiss')
        | Abbrechen
      v-btn(color='primary' text='' @click='submit')
        | Fertig

</template>
<script>
import Vue from 'vue';
import { areaCategories } from '../../assets/selections/areas';
import { cloneDeep } from 'lodash';
export default Vue.extend({
  props: ['areaItems', 'required'],
  data() {
    return {
      areaCategories: areaCategories(),
      dialog: false,
      selectedAreas: cloneDeep(this.areaItems) ?? [],
      updateAreas: cloneDeep(this.areaItems) ?? [],
    };
  },
  methods: {
    scroll(id) {
      setTimeout(
        function () {
          document.getElementById(id).scrollIntoView({
            behavior: 'smooth',
          });
        }.bind(this),
        400
      );
    },
    async submit() {
      this.selectedAreas = cloneDeep(this.updateAreas);
      this.$emit('areaChanged', this.selectedAreas);
      // await this.$refs.areaCombobox.focus();
      this.dialog = false;
    },
    async dismiss() {
      this.updateAreas = cloneDeep(this.areaItems);
      this.selectedAreas = cloneDeep(this.areaItems);
      // await this.$refs.areaCombobox.focus();
      this.dialog = false;
    },
    isSelected(select) {
      // return true;
      return this.updateAreas
        ? this.updateAreas.some((item) => item.id === select.id)
        : false;
    },
    // clearSelected(e) {
    //   // this.dismiss();
    //   this.updateAreas = [];
    //   // this.$emit('areaChanged', []);
    // },
    toggleSelected(select) {
      if (this.isSelected(select)) {
        const index = this.updateAreas.findIndex(
          (item) => item.id == select.id
        );
        this.updateAreas.splice(index, 1);
      } else {
        this.updateAreas.push(select);
      }
      // this.$emit('areaChanged', this.updateAreas);
    },
    countSelectedElements(element) {
      // const selected = this.selectedAreas;
      const length = element.items.filter((item) => {
        return this.isSelected(item);
      }).length;
      return length;
    },
    isSelectedElement(element) {
      return this.countSelectedElements(element) > 0;
    },
  },
});
</script>
<style lang="scss" scoped>
.textBold {
  font-weight: 700;
}
.list-title {
  align-items: center;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  display: inline;

  padding: 8px;
  font-size: 0.9375rem;
  line-height: 1;
}

.combobox {
  width: 100%;
  height: auto;
  position: relative;
  .click-item {
    width: 100%;
    height: auto;
    cursor: pointer;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
  }
  .v-input {
    pointer-events: none;
  }
}
.area-select {
  .v-list-item__action {
    margin-right: 8px;
  }
  .v-list-item__title {
    padding-left: 12px;
  }
}
</style>
