<template lang="pug">
div
    v-dialog(ref='dialog'  v-model='dialog' :close-on-content-click='false' transition='scale-transition' width="290px")
        template(v-slot:activator='{ on, attrs }')
            .input-wrapper
                .click-item(v-bind='attrs' v-on='on' )
                v-btn.clear-icon(icon @click='clearValue'  v-if=" value")
                    v-icon mdi-close
                v-text-field(:value="formattedBirthdate" dense :label='label'  outlined hide-details :required='required' append-icon='mdi-calendar' readonly)
        v-date-picker(ref='picker' :type="type ? type : 'date'" dense :value='value' @input="handleInput" :max='max' :min='min')
            v-spacer
            v-btn(text='' color='grey' @click='dialog = false')
                | Abbrechen

    </template>

<script>
import Vue from 'vue';
import { format, parseISO } from 'date-fns';
import { de } from 'date-fns/locale';
import { calculateAge, formatDateMonth } from '@/utilities/DateAndTime';

export default Vue.extend({
  props: [
    'value',
    'label',
    'type',
    'required',
    'min',
    'max',
    'startWithYear',
    'showAge',
  ],
  data() {
    return {
      dialog: false,
      calculateAge: calculateAge,
    };
  },
  watch: {
    dialog(val) {
      if (this.startWithYear) {
        val && this.$nextTick(() => (this.$refs.picker.activePicker = 'YEAR'));
      }
    },
  },

  methods: {
    clearValue() {
      this.handleInput(null);
    },
    formatDate(date) {
      return date
        ? format(parseISO(date), 'dd.MM.yyyy', {
            locale: de,
          })
        : '';
    },
    handleInput(e) {
      this.$refs.dialog.save(e);
      this.$emit('input', e);
    },
  },
  computed: {
    formattedBirthdate() {
      if (this.showAge) {
        return this.value
          ? `${this.formatDate(this.value)} (${this.calculateAge(this.value)})`
          : '';
      } else {
        return formatDateMonth(this.value);
      }
    },
  },
});
</script>
<style lang="scss">
@import '@/assets/style/main';
.input-wrapper {
  width: 100%;
  height: auto;
  position: relative;
  .click-item {
    width: 100%;
    height: auto;
    cursor: pointer;
    position: absolute;
    right: 150;
    left: 0;
    top: 0;
    bottom: 0;
  }
  .v-input {
    pointer-events: none;
  }
  .clear-icon {
    position: absolute;
    right: 32px;
    top: 2px;
  }
}
</style>
